import React from "react";

import { Route, Switch } from "react-router-dom";
import CreateMerchant from "./components/createMerchant";
import Transaction from "./components/transaction";
import Users from "./components/users";
import Withdrawals from "./components/withdrawals";
import WhiteLabelRequests from "./components/whitelabelRequests";
import WalletHistory from "./components/UserTransaction";
import GetNetwork from "../NetworkSwitching/GetNetwork";

class Mobile extends React.Component {
    constructor() {
        super();
        this.state = {
            someKey: "someValue",
        };
    }

    render() {
        return (
            <div>
                <Switch>
                    <Route
                        exact
                        path="/mobile/transactions"
                        key={2}
                        render={(routerProps) => <Transaction></Transaction>}
                    />
                    <Route
                        exact
                        path="/mobile/whitelabel-requests"
                        key={3}
                        render={(routerProps) => <WhiteLabelRequests></WhiteLabelRequests>}
                    />
                    <Route
                        exact
                        path="/mobile/users"
                        key={3}
                        render={(routerProps) => <Users></Users>}
                    />
                    <Route
                        exact
                        path="/mobile/daily-limit"
                        key={4}
                        render={(routerProps) => <CreateMerchant></CreateMerchant>}
                    />
                    <Route
                        exact
                        path="/mobile/withdrawals"
                        key={4}
                        render={(routerProps) => <Withdrawals></Withdrawals>}
                    />
                    <Route
                        exact
                        path="/mobile/wallet-history"
                        key={4}
                        render={(routerProps) => <WalletHistory></WalletHistory>}
                    />
                    <Route
                        exact
                        path="/mobile/get-network"
                        key={23}
                        render={(routerProps) => <GetNetwork></GetNetwork>}
                    />
                </Switch>
            </div>
        );
    }

    componentDidMount() {
        this.setState({
            someKey: "otherValue",
        });
    }
}

export default Mobile;
