import React from "react";
import Select from "react-select";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { WEB_BASE_URL } from "./webbase";

class SignUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      roleOptions: [
        { value: "merchant", label: "Merchant" },
        { value: "agent", label: "Agent" },
      ],
      stateOptions: [],
      history: this.props.history,
      // input variables
      formEmail: "",
      dealer: "",
      formRole: "",
      formPassword: "",
      formConfirmPassword: "",
      formFirstName: "",
      formLastName: "",
      formPhone: "",
      formState: "",
      formLocation: "",
      formMerchantName: "",
      formDealerName: "",
      transactionPin: "",
    };

    //bind all functions
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeRole = this.onChangeRole.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeFirst = this.onChangeFirst.bind(this);
    this.onChangeLast = this.onChangeLast.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangeLocation = this.onChangeLocation.bind(this);
    this.onChangeMerchantName = this.onChangeMerchantName.bind(this);
    this.onChangeDealerName = this.onChangeDealerName.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onTransactionPinChange = this.onTransactionPinChange.bind(this);
  }

  onTransactionPinChange = (e) => {
    this.setState({
      transactionPin: e.target.value,
    });
  };

  //Onsubmit
  onSubmit = (e) => {
    e.preventDefault();

    const user = this.state.formFirstName.split(" ");
    const id = toast.loading("Please wait...");
    axios
      .post(WEB_BASE_URL + "/api/v2/w3/users-signup", {
        firstName: user[0],
        lastName: user[1],
        password: this.state.formPassword,
        phoneNumber: this.state.formPhone,
        emailAddress: this.state.formEmail,
      })
      .then((res) => {
        console.log(res.data);
        toast.update(id, {
          render: "Verification Email Sent",
          type: "success",
          isLoading: false,
        });
        this.props.history.push("/verifyEmail", {
          requestId: res.data.requestId,
          userEmailAddress: this.state.formEmail,
        });
      })
      .catch((err) => {
        console.log(WEB_BASE_URL + "/api/v2/w3/users-signup");
        toast.update(id, {
          render: "" + err,
          type: "error",
          isLoading: false,
        });
        setTimeout(() => {
          toast.dismiss(id);
        }, 2000);
      });
  };

  //set state for formEmail
  onChangeEmail = (e) => {
    this.setState({
      formEmail: e.target.value,
    });
  };

  //set state for formRole
  onChangeRole = (e) => {
    this.setState({
      formRole: e.target.value,
    });
  };

  onChangeState = (e) => {
    this.setState({
      formState: e.value,
      dealer: e.dealer,
    });
  };

  //set state for formPassword
  onChangePassword = (e) => {
    this.setState({
      formPassword: e.target.value,
    });
  };

  // set state for formFirst
  onChangeFirst = (e) => {
    this.setState({
      formFirstName: e.target.value,
    });
  };

  // set state for formLast
  onChangeLast = (e) => {
    this.setState({
      formLastName: e.target.value,
    });
  };

  // set state for formPhone
  onChangePhone = (e) => {
    this.setState({
      formPhone: e.target.value,
    });
  };

  //set state for formLocation
  onChangeLocation = (e) => {
    this.setState({
      formLocation: e.target.value,
    });
  };

  //set state for formMerchantName
  onChangeMerchantName = (e) => {
    this.setState({
      formMerchantName: e.target.value,
    });
  };

  //set state for formDealerName
  onChangeDealerName = (e) => {
    this.setState({
      formDealerName: e.target.value,
    });
  };

  componentDidMount() {}

  render() {
    const customStyles = {
      menu: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted pink",
        color: state.selectProps.menuColor,
        padding: 20,
      }),

      control: (provided, { selectProps: { width } }) => ({
        ...provided,
        borderRadius: "25px",
        padding: "2px 4px",
        width: width,
        minWidth: "310px",
      }),
    };
    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <main className="authentication-content">
          <div className="container-fluid">
            <div className="authentication-card">
              <div className="card shadow rounded-0 overflow-hidden">
                <div className="row g-0">
                  <div className="col-lg-6 bg-login d-flex align-items-center justify-content-center">
                    <img
                      src="assets/images/error/login-img.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="col-lg-6">
                    <div className="card-body p-4 p-sm-5">
                      <h5 className="card-title">Sign Up</h5>
                      <p className="card-text mb-2">Register to get started!</p>
                      <form
                        className="form-body"
                        onSubmit={(event) => {
                          event.preventDefault();
                          this.onSubmit(event);
                        }}
                      >
                        <div className="row g-3">
                          <div className="col-12 ">
                            <div className="ms-auto position-relative">
                              <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                <i className="bi bi-person-circle"></i>
                              </div>
                              <input
                                type="text"
                                className="form-control radius-30 ps-5"
                                id="inputName"
                                placeholder="Enter First Name and Last Name"
                                onChange={this.onChangeFirst}
                                value={this.state.formFirstName}
                                required
                              />
                            </div>
                          </div>
                          <div
                            className="col-12"
                            styles={{ marginTop: 10 + "px" }}
                          >
                            <div className="ms-auto position-relative">
                              <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                <i className="bi bi-person-circle"></i>
                              </div>
                              <input
                                type="text"
                                className="form-control radius-30 ps-5"
                                id="inputName"
                                placeholder="Phone Number"
                                onChange={this.onChangePhone}
                                value={this.state.formPhone}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="ms-auto position-relative">
                              <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                <i className="bi bi-envelope-fill"></i>
                              </div>
                              <input
                                type="email"
                                className="form-control radius-30 ps-5"
                                id="inputEmailAddress"
                                placeholder="Email Address"
                                onChange={this.onChangeEmail}
                                value={this.state.formEmail}
                                required
                              />
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="ms-auto position-relative">
                              <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                <i className="bi bi-lock-fill"></i>
                              </div>
                              <input
                                type="password"
                                className="form-control radius-30 ps-5"
                                id="inputChoosePassword"
                                placeholder="Enter Password"
                                onChange={this.onChangePassword}
                                value={this.state.formPassword}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="flexSwitchCheckChecked"
                              />
                              <label
                                className="form-check-label"
                                for="flexSwitchCheckChecked"
                              >
                                I Agree to the{" "}
                                <a href="/">Terms & Conditions</a>
                              </label>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="d-grid">
                              <button
                                type="submit"
                                className="btn btn-primary radius-30"
                              >
                                Get Started
                              </button>
                            </div>
                          </div>
                          <div className="col-12 text-center">
                            <p className="mb-0">
                              Already have an account?{" "}
                              <a href="/signIn">Sign in here</a>
                            </p>
                            <div class="breadcrumb-item active mt-3">
                              Copyright © 2022 TopUpBox. All rights reserved.
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default SignUp;
