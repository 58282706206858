import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import cookie from "react-cookies";
import Swal from "sweetalert2";
import { CLIQ_BASE_URL } from '../webbase';
function GetNetwork() {
    const [network, setNetwork] = useState([])
    const token = cookie.load("token")

    useEffect(() =>{
        axios
        .get(CLIQ_BASE_URL + "/api/v3/w3/get-sme-merchants", {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
         
         setNetwork(res.data.data2)
        })
        .catch((err) => {
          console.log(err);
        });
    },[token])
    
    const toggleSme = (email, status) =>{
        Swal.fire({
            title: 'Are you sure?',
            text: "You are about to change the status of this SME merchant",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, toggle it!'
          }).then((result) => {
            if (result.isConfirmed) {
              const id = toast.loading("Please wait...");
              axios
              .get(CLIQ_BASE_URL + `/api/v3/w3/toggle-sme-merchant?merchantEmail=${email}&status=${status}`, {
                headers: {
                  authorization: token,
                },
              })
                .then((res) => {
                  console.log(res);
                  if (res.data.status === "2000") {
                    toast.update(id, {
                      render: res.data.description,
                      type: "success",
                      isLoading: false,
                    });
                    window.location.reload();
                  } else {
                    toast.update(id, {
                      render: res.data.description,
                      type: "error",
                      isLoading: false,
                    });
                  }

                })
                .catch((err) => {
                  console.log(err);
                });
            }
          });
       
    }
  return (
    <main className="page-content">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
         <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="javascript:;">
                    <i className="bx bx-home-alt"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  All Users
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <h5 className="mb-0">SME Merchant</h5>
             
            </div>
            <div className="table-responsive mt-3">
              <table className="table align-middle">
                <thead className="table-secondary">
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Base url</th>
                    <th>Email address</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Toggle </th>
                  </tr>
                </thead>
                <tbody>
                  {network.map((user, index) => {
                    const transactionDate = new Date(user?.createdDate);
                      const formattedDate = transactionDate.toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      });

                      const formattedTime = transactionDate.toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      });
                    return (
                    <>
                      <tr key={index}>
                        <td>{index + 1}</td>
                       
                        <td>{user?.merchantName}</td>
                        <td>{user?.baseUrl}</td>
                        <td width={50}>{user?.emailAddress}</td>
                        <td>{formattedDate} {formattedTime}</td>
                        <td>{user?.status}</td>
                        <td>
                        <div className="table-actions d-flex align-items-center gap-3 ">
                        <div></div>
                          <a
                            href="javascript:;"
                            className= {user?.status === "false"?`text-danger`: `text-success `}
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                             title= {user?.status === "false"?`Activate user Dashboard`: `Deactivate user Dashboard `}
                             onClick={(e) => toggleSme(user?.emailAddress,user?.status=='false'?'active': 'inactive')}
                            >
                              <i className="bi bi-toggle-on"></i>
                          </a>
                        </div>
                      </td>
                       
                      </tr>
                      
                    </>
                  )})}
                </tbody>
              </table>
            </div>
          </div>
        </div>
    </main>
  )
}

export default GetNetwork