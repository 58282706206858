let States = [
    {
        "name": "Abia",
        "lgas": [
            "Aba North",
            "Aba South",
            "Arochukwu",
            "Bende",
            "Ikwuano",
            "Isuikwuato",
            "Ohafia",
            "Ukwa East",
            "Ukwa West",
            "Umuahia North",
            "Umuahia South"
        ]
    },
    {
        "name": "Adamawa",
        "lgas": [
            "Akwanga",
            "Biu",
            "Damboa",
            "Fufore",
            "Ganye",
            "Gaya",
            "Gombi",
            "Hong",
            "Jada",
            "Jimeta",
            "Mubi North",
            "Mubi South",
            "Maiha",
            "Michika",
            "Madagali",
            "Song",
            "Tarba",
            "Yola North",
            "Yola South"
        ]
    },
    {
        "name": "Akwa Ibom",
        "lgas": [
            "Abak",
            "Eket",
            "Essien Udim",
            "Ibeno",
            "Ikot Abasi",
            "Ikot Ekpene",
            "Ikot Essien",
            "Itu",
            "Mbo",
            "Nsit Atai",
            "Nsit Ubium",
            "Obot Akara",
            "Onna",
            "Ukanafun",
            "Uyo"
        ]
    },
    {
        "name": "Anambra",
        "lgas": [
            "Anambra East",
            "Anambra West",
            "Awka North",
            "Awka South",
            "Ekwusigo",
            "Idemili North",
            "Idemili South",
            "Njikoka",
            "Onitsha North",
            "Onitsha South",
            "Orumba North",
            "Orumba South",
            "Ogbaru"
        ]
    },
    {
        "name": "Bauchi",
        "lgas": [
            "Azare",
            "Bauchi",
            "Bogoro",
            "Dambam",
            "Dukku",
            "Gamawa",
            "Giade",
            "Itas Gadau",
            "Katagum",
            "Kirfi",
            "Kano",
            "Kirfi",
            "Maiduguri",
            "Misau",
            "Ningi",
            "Shira",
            "Toro",
            "Warji",
            "Yankari"
        ]
    },
    {
        "name": "Bayelsa",
        "lgas": [
            "Brass",
            "Ekeremor",
            "Kolokuma/Opokuma",
            "Nembe",
            "Ogbia",
            "Sagbama",
            "Southern Ijaw",
            "Yenegoa"
        ]
    },
    {
        "name": "Benue",
        "lgas": [
            "Ado",
            "Agatu",
            "Apa",
            "Bende",
            "Bonny Island",
            "Buruku",
            "Gboko",
            "Guma",
            "Katsina-Ala",
            "Konshisha",
            "Kwande",
            "Logo",
            "Makurdi",
            "Obi",
            "Oju",
            "Okpokwu",
            "Onu-Aya",
            "Oturkpo",
            "Ugbokolo",
            "Ukum",
            "Yagba East",
            "Yagba West"
        ]
    },
    {
        "name": "Borno",
        "lgas": [
            "Abakaliki",
            "Afikpo North",
            "Afikpo South",
            "Ezza North",
            "Ezza South",
            "Ikwo",
            "Ishielu",
            "Onicha",
        ],
    },
    {
        "name": "Cross River",
        "lgas": [
            "Akamkpa",
            "Akpabuyo",
            "Biase",
            "Bende",
            "Bonny Island",
            "Calabar Municipal",
            "Calabar South",
            "Etung",
            "Ikom",
            "Obanliku",
            "Obudu",
            "Ogoja",
            "Yala"
        ]
    },
    {
        "name": "Delta",
        "lgas": [
            "Aniocha North",
            "Aniocha South",
            "Bomadi",
            "Burutu",
            "Effurun",
            "Ethiope East",
            "Ethiope West",
            "Ika North East",
            "Ika South",
            "Isoko North",
            "Isoko South",
            "Ndokwa East",
            "Ndokwa West",
            "Okpe",
            "Oshimili North",
            "Oshimili South",
            "Patani",
            "Warri North",
            "Warri South",
            "Udu"
        ]
    },
    {
        "name": "Ebonyi",
        "lgas": [
            "Afikpo North",
            "Afikpo South",
            "Ezza North",
            "Ezza South",
            "Ikwo",
            "Ishielu",
            "Onicha",
            "Ohaozara",
            "Ohaukwu"
        ]
    },
    {
        "name": "Edo",
        "lgas": [
            "Akoko-Edo",
            "Egor",
            "Esan Central",
            "Esan North East",
            "Esan South East",
            "Esan South West",
            "Igueben",
            "Oredo",
            "Orhionmwon",
            "Uhumwonde",
            "Uromi",
            "Etsako Central",
            "Etsako East",
            "Etsako West"
        ]
    },
    {
        "name": "Ekiti",
        "lgas": [
            "Ado Ekiti",
            "Efon Alaaye",
            "Ido/Osi",
            "Ikere",
            "Ilejemeje",
            "Irepodun/Ifelodun",
            "Ise/Orun",
            "Moba",
            "Oye"
        ]
    },
    {
        "name": "Enugu",
        "lgas": [
            "Aninri",
            "Awgu",
            "Enugu East",
            "Enugu North",
            "Enugu South",
            "Ezeagu",
            "Igbo-Etiti",
            "Igbo-Eze North",
            "Igbo-Eze South",
            "Isi-Uzo",
            "Nsukka",
            "Oji-River",
            "Udi",
            "Uzo-Uwani"
        ]
    },
    {
        "name": "Gombe",
        "lgas": [
            "Akko",
            "Balanga",
            "Billiri",
            "Dukku",
            "Gombe",
            "Kaltungo",
            "Kwami",
            "Shomgom",
            "Yala"
        ]
    },
    {
        "name": "Imo",
        "lgas": [
            "Aboh Mbaise",
            "Ehime Mbano",
            "Ikeduru",
            "Imo",
            "Obowo",
            "Orlu",
            "Orsu",
            "Owerri Municipal",
            "Owerri North",
            "Owerri West",
            "Okigwe",
            "Orumba North",
            "Orumba South"
        ]
    },
    {
        "name": "Jigawa",
        "lgas": [
            "Auyo",
            "Batagarawa",
            "Buji",
            "Dutse",
            "Gumel",
            "Guri",
            "Gwaram",
            "Hadejia",
            "Kafin Hausa",
            "Kano",
            "Kirfi",
            "Maiduguri",
            "Miga",
            "Ringim",
            "Roni",
            "Sule Tankarkar",
            "Yankwashi"
        ]
    },
    {
        "name": "Kaduna",
        "lgas": [
            "Birnin Gwari",
            "Chikun",
            "Giwa",
            "Ikara",
            "Jaba",
            "Kaduna North",
            "Kaduna South",
            "Kajuru",
            "Kaura",
            "Kauru",
            "Kebbe",
            "Kediya",
            "Kogi",
            "Konduga",
            "Kunchi",
            "Lere",
            "Makarfi",
            "Kachia",
            "Sabon Gari",
            "Sanga",
            "Soba",
            "Zaria"
        ]
    },
    {
        "name": "Kano",
        "lgas": [
            "Ajingi",
            "Albasu",
            "Bichi",
            "Dawakin Kudu",
            "Dawakin Tofa",
            "Fagge",
            "Gwale",
            "Kano Municipal",
            "Kano South",
            "Kano North",
            "Karaye",
            "Kiru",
            "Kumbotso",
            "Rano",
            "Rogo",
            "Shanono",
            "Tudun Wada",
            "Ungogo",
            "Warawa"
        ]
    },
    {
        "name": "Katsina",
        "lgas": [
            "Batsari",
            "Dandume",
            "Dan Musa",
            "Daura",
            "Famfuri",
            "Funtua",
            "Jibia",
            "Kafur",
            "Kankia",
            "Katsina",
            "Kurfi",
            "Mai'Adua",
            "Malumfashi",
            "Matazu",
            "Musawa",
            "Rimi",
            "Sabuwa",
            "Zango"
        ]
    },
    {
        "name": "Kebbi",
        "lgas": [
            "Aleiro",
            "Argungu",
            "Augie",
            "Birnin Kebbi",
            "Dandi",
            "Fakai",
            "Gwandu",
            "Jega",
            "Kalgo",
            "Koko-Besse",
            "Kontagora",
            "Kura",
            "Maiyama",
            "Sokoto South",
            "Sokoto North",
            "Yauri",
            "Zuru"
        ]
    },
    {
        "name": "Kogi",
        "lgas": [
            "Adavi",
            "Ajaokuta",
            "Ankpa",
            "Bassa",
            "Dekina",
            "Igalamela Odolu",
            "Ijumu",
            "Kabba/Bunu",
            "Kogi",
            "Mopa-Muro",
            "Okene",
            "Olamaboro",
            "Ofu",
            "Ogori/Magongo",
            "Okehi",
            "Yagba East",
            "Yagba West"
        ]
    },
    {
        "name": "Kwara",
        "lgas": [
            "Asa",
            "Baruten",
            "Ekiti",
            "Ifelodun",
            "Ilorin East",
            "Ilorin South",
            "Irepodun",
            "Isin",
            "Kwara North",
            "Moro",
            "Offa",
            "Oke-Ero",
            " Oyun"
        ]
    },
    {
        "name": "Lagos",
        "lgas": [
            "Apapa",
            "Badagry",
            "Epe",
            "Ikeja",
            "Ikorodu",
            "Ikoyi-Obalende",
            "Lagos Island",
            "Lagos Mainland",
            "Mushin",
            "Ojo",
            "Surulere"
        ]
    },
    {
        "name": "Ogun",
        "lgas": [
            "Abeokuta North",
            "Abeokuta South",
            "Ado-Odo/Ota",
            "Egbado North",
            "Egbado South",
            "Ewekoro",
            "Ifo",
            "Ijebu East",
            "Ijebu North",
            "Ijebu North East",
            "Ijebu Ode",
            "Ogun East",
            "Ogun West",
            "Ore-Ogun",
            "Sagamu",
            "Yewa North",
            "Yewa South"
        ]
    },
    {
        "name": "Ondo",
        "lgas": [
            "Akoko North East",
            "Akoko North West",
            "Akoko South East",
            "Akoko South West",
            "Akure North",
            "Akure South",
            "Ese-Ondo",
            "Idanre",
            "Ondo East",
            "Ondo West",
            "Ose",
            "Owo"
        ]
    },
    {
        "name": "Osun",
        "lgas": [
            "Aiyedire",
            "Ado-Ekiti",
            "Ado-Osun",
            "Bode-Gberigbe",
            "Ede North",
            "Ede South",
            "Ife Central",
            "Ife East",
            "Ife North",
            "Ife South",
            "Ifelodun",
            "Ila Orangun",
            "Ile-Ife",
            "Isokan",
            "Obokun",
            "Odo-Otin",
            "Olorunda",
            "Orolu",
            "Osogbo",
            "Osun East",
            "Osun West"
        ]
    },
    {
        "name": "Oyo",
        "lgas": [
            "Atiba",
            "Atisbo",
            "Awajun",
            "Bode-Thomas",
            "Ibadan Central",
            "Ibadan East",
            "Ibadan North",
            "Ibadan South",
            "Ibadan West",
            "Ifedayo",
            "Ifelodun",
            "Irepo",
            "Iseyin",
            "Itesiwaju",
            "Kajola",
            "Lagelu",
            "Ogbomosho North",
            "Ogbomosho South",
            "Ogo Oluwa",
            "Olorunsogo",
            "Orelope",
            "Ori Ire",
            "Oyo East",
            "Oyo West",
            "Saki East",
            "Saki West",
            "Surulere"
        ]
    },
    {
        "name": "Plateau",
        "lgas": [
            "Barkin Ladi",
            "Bassa",
            "Bokkos",
            "Donga",
            "Jos East",
            "Jos North",
            "Jos South",
            "Kanam",
            "Langtang North",
            "Langtang South",
            "Mangu",
            "Mikang",
            "Qua'an Pan",
            "Riyom",
            "Wase"
        ]
    },
    {
        "name": "Rivers",
        "lgas": [
            "Ahoada East",
            "Ahoada West",
            "Akuku Toru",
            "Andoni",
            "Bonny",
            "Degema",
            "Eleme",
            "Emohua",
            "Etche",
            "Gokana",
            "Ikwerre",
            "Khana",
            "Obio-Akpor",
            "Ogba/Egbema/Ndoni",
            "Ogoni",
            "Port Harcourt",
            "Tai",

        ]
    },
    {
        "name": "Sokoto",
        "lgas": [
            "Binji",
            "Bodinga",
            "Dambam",
            "Gada",
            "Goronyo",
            "Gudu",
            "Kebbe",
            "Kware",
            "Rabah",
            "Sokoto North",
            "Sokoto South",
            "Tambuwal",
            "Wurno"
        ]
    },
    {
        "name": "Taraba",
        "lgas": [
            "Ardo-Kola",
            "Bali",
            "Donga",
            "Gashaka",
            "Ibi",
            "Jalingo",
            "Karim Lamido",
            "Kurmi",
            "Lau",
            "Sardauna",
            "Ussa",
            "Wukari",
            "Yorro"
        ]
    },
    {
        "name": "Yobe",
        "lgas": [
            "Biu",
            "Damboa",
            "Gubio",
            "Gujba",
            "Gulbin Boka",
            "Kaga",
            "Kala/Balge",
            "Maiduguri",
            "Nganzai",
            "Pulka",
            "Tarmuwa",
            "Yusufari"
        ]
    },
    {
        "name": "Zamfara",
        "lgas": [
            "Bakura",
            "Birnin Magaji/Gummi",
            "Bukkuyum",
            "Dambam",
            "Gusau",
            "Kaura Namoda",
            "Maradun",
            "Shinkafi",
            "Talata Mafara",
            "Zamfara"
        ]
    },
    {
        "name": "Federal Capital Territory",
        "lgas": [
            "Abuja Municipal Area Council",
            "Bwari",
            "Kuje",
            "Gwagwalada",
            "Kusa",
            "Abaji",
            "Njikoka",
            "Onitsha North",
            "Onitsha South"
        ]
    },
]

export default States;