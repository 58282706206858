import React from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import cookie from "react-cookies";
import { WEB_BASE_URL } from "./webbase";

class ResetPin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: props.location.state.userEmailAddress,
      requestID: props.location.state.requestID,
      token: props.location.state.token,
      formOtp: "",
      formPassword: "",
      formConfirmPassword: "",
    };

    console.log(this.state.email, this.state.requestID);
    //bind all events
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeOtp = this.onChangeOtp.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
  }

  onChangeOtp = (event) => {
    this.setState({
      formOtp: event.target.value,
    });
  };

  onChangePassword = (event) => {
    this.setState({
      formPassword: event.target.value,
    });
  };

  onChangeConfirmPassword = (event) => {
    this.setState({
      formConfirmPassword: event.target.value,
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    const id = toast.loading("Please wait...");

    //axios post to sign in
    axios
      .post(
        WEB_BASE_URL + "/admin/reset-pin",
        {
          otp: this.state.formOtp,
          requestId: this.state.requestID,
        },
        {
          headers: {
            authorization: this.state.token,
          },
        }
      )
      .then((res) => {
        console.log(res);
        console.log({
          otp: this.state.formOtp,
          requestID: this.state.requestID,
        });
        let iflog = res.data.status == 2000 ? true : false;

        if (iflog) {
          toast.update(id, {
            render: "Successfully reset password",
            type: "success",
            isLoading: false,
          });

          this.props.history.push("/signIn");
        } else {
          toast.update(id, {
            render: "" + res.data.description,
            type: "error",
            isLoading: false,
          });
          setTimeout(() => {
            toast.dismiss(id);
          }, 2000);
        }
      })
      .catch((err) => {
        toast.update(id, {
          render: err,
          type: "error",
          isLoading: false,
        });
        setTimeout(() => {
          toast.dismiss(id);
        }, 2000);
      });
  };

  render() {
    return (
      <div class="">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <main class="authentication-content">
          <div class="container-fluid">
            <div class="authentication-card">
              <div class="card shadow rounded-0 overflow-hidden">
                <div class="row g-0">
                  <div class="col-lg-6 bg-login d-flex align-items-center justify-content-center">
                    <img
                      src="assets/images/error/login-img.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <div class="col-lg-6">
                    <div class="card-body p-4 p-sm-5">
                      <h5 class="card-title">
                        Reset your Two Factor Authentication?
                      </h5>
                      <p class="card-text mb-5">
                        Enter your OTP sent to your mail
                      </p>
                      <form
                        class="form-body"
                        onSubmit={(event) => {
                          event.preventDefault();
                          this.onSubmit(event);
                        }}
                      >
                        <div class="row g-3">
                          <div class="col-12">
                            <div class="ms-auto position-relative">
                              <div class="position-absolute top-50 translate-middle-y search-icon px-3">
                                <i class="bi bi-envelope-fill"></i>
                              </div>
                              <input
                                type="text"
                                class="form-control radius-30 ps-5"
                                id="inputEmailAddress"
                                placeholder="Email OTP"
                                required={true}
                                onChange={this.onChangeOtp}
                              />
                            </div>
                          </div>

                          <div class="col-12">
                            <div class="d-grid">
                              <button
                                type="submit"
                                class="btn btn-primary radius-30"
                              >
                                Reset Two Factor
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }

  componentDidMount() { }
}

export default ResetPin;
