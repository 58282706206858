import React from "react";
import Modal from "../../modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import cookie from "react-cookies";
import Switch from "react-switch";
import { ENTERPRISE_URL, UNIFIED_BASE_URL, WEB_BASE_URL } from "../../webbase";
class Switche extends React.Component {
  constructor() {
    super();
    this.state = {
      userEmail: cookie.load("useAdmin"),
      token: cookie.load("tokenAdmin"),
      ratios: [],
      checked: false,
      users: [],
      selectedDealer1: "",
      selectedDealer2: "",
      dealer2Ratio: 1,
      dealer1Ratio: 1,
      network: [
        { id: 1, name: "MTN" },
        { id: 2, name: "Airtel" },
        { id: 3, name: "Glo" },
        { id: 4, name: "9mobile" },
      ],

    };
    this.getRatios = this.getRatios.bind(this);
    this.getDealers = this.getDealers.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }



  onSubmit = (event) => {
    event.preventDefault();
    const data = {
      dealerId: this.state.selectedDealer1 + "," + this.state.selectedDealer2,
      telconame: this.state.selectedNetwork,
      merchantId: this.state.merchantId,
      dealer1Ratio: this.state.dealer1Ratio,
    }

    axios
      .post(ENTERPRISE_URL + "/manage/switch-or-ratio-dealer", data, {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "2000") {
          toast.success(res.data.description);
          this.getRatios();
        } else {
          toast.error(res.data.description);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //get all users
  getRatios() {
    axios
      .get(ENTERPRISE_URL + "/manage/get-dealer-router-config", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "2000") {
          this.setState({
            ratios: res.data.data,
          });
        } else {
          toast.error(res.data.description);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getDealers() {
    axios
      .get(WEB_BASE_URL + "/admin/get-logical-dealer", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "2000") {
          this.setState({
            users: res.data.data.data,
            selectedDealer1: res.data.data.data[0].dealerId
          });
        } else {
          toast.error(res.data.description);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    this.getRatios();
    this.getDealers();
  }

  render() {
    return (
      <main className="page-content">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="javascript:;">
                    <i className="bx bx-home-alt"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  All Dealer Ratios
                </li>
              </ol>
            </nav>
          </div>
          <div className="ms-auto"></div>
        </div>

        <div className="card">
          <div className="card-body">
            <form className="row">
              <div className="row">

                <div className="col-lg-3 col-6 col-md-3">
                  <label className="form-label">Dealer</label>
                  <select
                    className="form-select"
                    value={this.state.selectedDealer1}
                    onChange={(event) => {
                      this.setState({
                        selectedDealer1: event.target.value,
                      });
                    }}
                  >
                    {
                      this.state.users.map((user, index) => (
                        <option key={index} value={user?.dealerId}>{user?.dealerId}</option>
                      ))
                    }
                  </select>
                </div>

                <div className="col-lg-3 col-6 col-md-3">
                  <label className="form-label">Dealer2</label>
                  <select
                    className="form-select"
                    value={this.state.selectedDealer2}
                    defaultValue={null}
                    onChange={(event) => {
                      this.setState({
                        selectedDealer2: event.target.value,
                      });
                    }}
                  >
                    <option value={"None"}>None</option>
                    {
                      this.state.users.map((user, index) => (
                        <option key={index} value={user?.dealerId}>{user?.dealerId}</option>
                      ))
                    }
                  </select>
                </div>

                <div className="col-3">
                  <label className="form-label">Network</label>
                  <select
                    className="form-select "
                    aria-label="Default select example"
                    value={this.state.selectedNetwork}
                    onChange={(event) => {
                      this.setState({
                        selectedNetwork: event.target.value
                      })
                    }}
                  >
                    {
                      this.state.network.map((network, index) => (
                        <option key={index} value={network.id}>{network.name}</option>
                      ))
                    }
                  </select>
                </div>

                <div className="col-3">
                  <label className="form-label">Merchant ID</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Merchant ID"
                    value={this.state.merchantId}
                    onChange={(event) => {
                      this.setState({
                        merchantId: event.target.value
                      })
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-3 mt-3">
                  <label className="form-label">Dealer 1 Ratio:</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Merchant ID"
                    value={this.state.dealer1Ratio}
                    onChange={(event) => {
                      this.setState({
                        dealer1Ratio: event.target.value
                      })
                    }}
                  />
                </div>
                <div className="col-3 mt-3">
                  <label className="form-label">Dealer 2 Ratio:</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Merchant ID"
                    value={this.state.dealer2Ratio}
                    onChange={(event) => {
                      this.setState({
                        dealer2Ratio: event.target.value
                      })
                    }}
                  />
                </div>
                <div className="col-3"
                  style={{
                    marginTop: "2.7rem"
                  }}>
                  <button
                    className="btn btn-primary d-flex"
                    type="button"
                    style={{
                      width: "100%"
                    }}
                    onClick={(event) => {
                      event.preventDefault();
                      this.onSubmit(event)
                    }}
                  >
                    <div className="mx-1">
                      Effect Ratio</div>
                  </button>

                </div>

              </div>
            </form>
          </div>
        </div >

        <div className="card">
          <div className="card-header py-3">
            <div className="row g-3">

              <div className="col-lg-2 col-6 col-md-3">
                <select className="form-select">
                  <option>Show 10</option>
                  <option>Show 30</option>
                  <option>Show 50</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table
                id="example"
                class="table table-striped table-bordered"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Dealer 1</th>
                    <th>Dealer 2</th>
                    <th>Network</th>
                    <th>Merchant ID</th>
                    <th>Transaction date</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.ratios.map((user, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{user.dealerId.split(",")[0]}</td>
                          <td>{user.dealerId.split(",")[1] ? user.dealerId.split(",")[1] : "None"}</td>
                          <td>{user.telcoName}</td>
                          <td>{user.merchantId}</td>
                          <td>{user?.transaction_date}</td>
                        </tr>
                      );
                    }
                    )
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Switche;
