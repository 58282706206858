//import React
import React from "react";
import logo from "./logo.svg";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import SignUp from "./components/signUp";
import SignIn from "./components/signIn";
import Dashboard from "./components/dashboard";
import VerifyEmail from "./components/verifyEmail";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import AuthPage from "./components/AuthenticatorSlide";
import CompleteSignup from "./components/CompleteSignUp";
import ResetPin from "./components/ResetPin";
import SuccessPage from "./components/successPage";

class App extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path="/signUp" component={SignUp} />
            <Route exact path="/signIn" component={SignIn} />
            <Route exact path="/verifyEmail" component={VerifyEmail} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/authenticate" component={AuthPage} />
            <Route path="/complete-signup" component={CompleteSignup} />
            <Route path="/resetPin" component={ResetPin} />
            <Route path="/successPage" component={SuccessPage} />
            <Route path="/" component={Dashboard} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
