import axios from "axios";
import React from "react";
import DatePicker from "react-datepicker";
import cookie from "react-cookies";
import "react-datepicker/dist/react-datepicker.css";
import { WEB_BASE_URL } from "../../webbase";
import TransferModal from "./TransferModal";

class Withdrawals extends React.Component {
  constructor() {
    super();
    this.state = {
      someKey: "someValue",
      startDate: new Date(),
      endDate: new Date(),
      transactions: [],
      fundingHistory: [],
      token: cookie.load("token"),
    };
    //binding this to event-handler functions
    this.setStartDate = this.setStartDate.bind(this);
    this.setEndDate = this.setEndDate.bind(this);
    this.getTransactions = this.getTransactions.bind(this);
  }
  setStartDate(date) {
    this.setState({
      startDate: date,
    });
  }

  //write a funtion to get the transactions
  getTransactions() {
    axios
      .get(WEB_BASE_URL + "/admin/list-transfers?from=&to=", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        console.log(res.data.data.data);
        //check if data isnt null
        if (res.data.data.data.data !== null) {
          this.setState({
            transactions: res.data.data.data,
          });
        }
        else {

          this.setState({
            transactions: [],
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }


  setEndDate(date) {
    this.setState({
      endDate: date,
    });
  }

  componentDidMount() {
    this.getTransactions();
  }

  render() {
    return (
      <main className="page-content">
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="javascript:;">
                    <i className="bx bx-home-alt"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Transactions
                </li>
              </ol>
            </nav>
          </div>

        </div>

        <div className="card">
          <div className="card-body">
            <form className="row">
              <div className="row">
                <div className="col-6">
                  <label className="form-label">Transaction Date:</label>
                  <DatePicker
                    selected={this.state.startDate}
                    onSelect={(date) => this.setStartDate(date)}
                    onChange={(date) => this.setStartDate(date)}
                  />
                </div>
                <div className="col-4">
                  <label className="form-label">Transaction Type</label>
                  <select
                    className="form-select "
                    aria-label="Default select example"
                    value={this.props.formRole}
                    onChange={(event) => this.props.onChangeRole(event)}
                  >
                    <option selected=""> Select Status</option>
                    <option value="1">All</option>
                    <option value="2">Used</option>
                    <option value="3">Unused</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <button
                    style={{ marginTop: 29 }}
                    className="btn btn-primary d-flex"
                  >
                    <div>
                      <i class="bi bi-cloud-arrow-down-fill"></i>
                    </div>
                    <div className="mx-3">Download Report</div>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="card">
          <div className="card-header py-3">
            <div className="row g-3">
              <div className="col-lg-3 col-md-6 me-auto">
                <div className="ms-auto position-relative">
                  <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                    <i className="bi bi-search"></i>
                  </div>
                  <input
                    className="form-control ps-5"
                    type="text"
                    placeholder="Search Payment"
                  />
                </div>
              </div>

              <div className="col-lg-2 col-6 col-md-3">
                <select className="form-select">
                  <option>Show 10</option>
                  <option>Show 30</option>
                  <option>Show 50</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table
                id="example"
                class="table table-striped table-bordered"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>User</th>
                    <th>Amount</th>
                    <th>Transaction date</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.transactions.map((transaction, index) => (
                      <>
                        <tr key={index} >
                          <td>{transaction?.id}</td>
                          <td>{transaction?.emailAddress}</td>
                          <td>NGN {transaction?.creditAmount}</td>
                          <td>{transaction?.transactionDate.split("T")[0]}</td>
                          <td>{transaction?.status}</td>
                          <td>
                            <button
                              className="btn btn-primary"
                              data-bs-toggle="modal"
                              data-bs-target={"#transModal" + index}
                              title="Approve Withdrawal"
                            >Approve
                            </button>
                          </td>
                        </tr>
                        <TransferModal
                          id={"transModal" + index}
                          amount={transaction?.creditAmount}
                          formEmail={transaction?.emailAddress}
                          requestID={transaction?.requestId}
                        />
                      </>
                    ))
                  }
                </tbody>
              </table>
            </div>
            <nav class="float-end mt-3">
              <ul class="pagination">
                <li class="page-item disabled">
                  <a class="page-link" href="#">
                    Previous
                  </a>
                </li>
                <li class="page-item active">
                  <a class="page-link" href="#">
                    1
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    2
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    3
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    Next
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div >
      </main >
    );
  }

}

export default Withdrawals;
