/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import axios from "axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import cookie from "react-cookies";
import { ToastContainer, toast } from "react-toastify";
import { WEB_BASE_URL } from "../../webbase";

class VouchersB extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      walletBalance: cookie.load("walletBalance"),
      token: cookie.load("token"),
      businessName: cookie.load("businessName"),
      vouchers: [],
      backup: [],
      readyPrint: false,
      readyVouchers: [],
      units: "",
      pin: "",
      user: {},
      selectedFilePin: "",
    };

    this.getVouchers = this.getVouchers.bind(this);
    this.setUnits = this.setUnits.bind(this);
    this.setPin = this.setPin.bind(this);
  }

  getVouchers = () => {
    const id = toast.loading("Please wait...");

    axios
      .get(WEB_BASE_URL + "/admin/logical-voucher-history", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        if (res.data.description == "No data available") {
          toast.update(id, {
            render: "No Vouchers Available",
            type: "error",
            isLoading: false,
          });
        } else {
          console.log(res.data);
          res.data.data.reverse();
          const filteredData = res.data.data.filter(
            (item) => item.orderStatus !== "executed"
          );
          this.setState({ vouchers: filteredData, backup: filteredData });
          toast.dismiss(id);
        }
      })
      .catch((err) => {
        toast.update(id, {
          render: "" + err,
          type: "error",
          isLoading: false,
        });
        // this.props.history.push("/signIn");
      });
  };

  setUnits(e) {
    this.setState({ units: e });
  }

  setPin(e) {
    this.setState({ pin: e.target.value });
  }

  componentDidMount() {
    this.getVouchers();
  }

  render() {

    return (
      <main className="page-content">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="javascript:;">
                    <i className="bx bx-home-alt"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Print Vouchers
                </li>
              </ol>
            </nav>
          </div>
          <div className="ms-auto">
            <div className="btn-group">
              <button type="button" className="btn btn-primary">
                Wallet Balance: &#8358;{this.state.walletBalance}
              </button>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header py-3">
            <div class="row g-3">
              <div class="col-lg-3 col-md-6 me-auto">
                <div class="ms-auto position-relative">
                  <div class="position-absolute top-50 translate-middle-y search-icon px-3">
                    <i class="bi bi-search"></i>
                  </div>
                  <input
                    class="form-control ps-5"
                    type="text"
                    placeholder="Search Batch"
                    onChange={(e) => {
                      let search = e.target.value;
                      if (search.length === 0) {
                        this.setState({
                          vouchers: this.state.backup,
                        });
                        return;
                      }
                      let vouchers = [].concat(this.state.vouchers);

                      let filteed = vouchers.filter((voucher) => {
                        return voucher.network
                          .toLowerCase()
                          .includes(search.toLowerCase());
                      });

                      this.setState({
                        vouchers: filteed,
                      });
                    }}
                  />
                </div>
              </div>
              <div class="col-lg-2 col-6 col-md-3">
                <select class="form-select">
                  <option>Show 10</option>
                  <option>Show 30</option>
                  <option>Show 50</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table align-middle mb-0">
                <thead class="table-light">
                  <tr>
                    <th>Batch ID</th>
                    <th>Username</th>
                    <th>Description</th>
                    <th>Units</th>
                    <th>Voucher denomination</th>
                    <th>Network</th>
                    <th>Bought On </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.vouchers.map((voucher) => (
                    <>
                      <tr>
                        <td>{voucher.id}</td>
                        <td>
                          <div class="d-flex align-items-center gap-3 cursor-pointer">
                            <div class="">
                              <p class="mb-0">{voucher.orderDescription}</p>
                            </div>
                          </div>
                        </td>
                        <td>{voucher.units}</td>
                        <td>&#8358;{voucher.unitAmount}</td>
                        <td>{voucher.network}</td>
                        <td>&#8358;{voucher.sellingPrice}</td>

                        <td>
                          {voucher.createdDate
                            ? voucher.createdDate.split("T")[0]
                            : null}
                        </td>
                        <td>
                          <a
                            href="javascript:void(0);"
                            class="text-success"
                            data-bs-toggle="modal"
                            data-bs-target={"#PrintModal" + voucher.id}
                            title="Download"
                            data-bs-original-title="Download"
                            aria-label="Download"
                          >
                            <i class="bi-cloud-arrow-down-fill"></i>
                          </a>
                        </td>
                      </tr>

                    </>
                  ))}
                </tbody>
              </table>
            </div>
            <nav class="float-end mt-3">
              <ul class="pagination">
                <li class="page-item disabled">
                  <a class="page-link" href="#">
                    Previous
                  </a>
                </li>
                <li class="page-item active">
                  <a class="page-link" href="#">
                    1
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    2
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    3
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    Next
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </main>
    );
  }
}

export default VouchersB;
