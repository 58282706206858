/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import axios from "axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import cookie from "react-cookies";
import { ToastContainer, toast } from "react-toastify";
import { WEB_BASE_URL } from "../../webbase";

class UploadHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      walletBalance: cookie.load("walletBalance"),
      token: cookie.load("token"),
      businessName: cookie.load("businessName"),
      vouchers: [],
      readyPrint: false,
      readyVouchers: [],
    };

    this.getVouchers = this.getVouchers.bind(this);
  }

  getVouchers = () => {
    const id = toast.loading("Please wait...");
    setInterval(() => {
      axios
        .get(WEB_BASE_URL + "/admin/logical-upload-history", {
          headers: {
            authorization: this.state.token,
          },
        })
        .then((res) => {
          let iflog = res.data.status == 2000 ? true : false;
          if (iflog) {
            this.setState({ vouchers: res.data.data.data });
            toast.dismiss(id);
          } else {
            console.log(res);
            toast.update(id, {
              render: "" + res.data.description,
              type: "error",
              isLoading: false,
            });
            setTimeout(() => {
              toast.dismiss(id);
            }, 2000);
          }
        })
        .catch((err) => {
          console.log(this.state.token);
          toast.update(id, {
            render: "" + err,
            type: "error",
            isLoading: false,
          });
        });
    }, 15000);
  };

  componentDidMount() {
    this.getVouchers();
  }

  render() {
    return (
      <main className="page-content">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="javascript:;">
                    <i className="bx bx-home-alt"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  View Upload History
                </li>
              </ol>
            </nav>
          </div>
          <div className="ms-auto">
            <div className="btn-group">
              <button type="button" className="btn btn-primary">
                Wallet Balance: &#8358;{this.state.walletBalance}
              </button>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header py-3">
            <div class="row g-3">
              <div class="col-lg-3 col-md-6 me-auto">
                <div class="ms-auto position-relative">
                  <div class="position-absolute top-50 translate-middle-y search-icon px-3">
                    <i class="bi bi-search"></i>
                  </div>
                  <input
                    class="form-control ps-5"
                    type="text"
                    placeholder="Search Payment"
                  />
                </div>
              </div>

              <div class="col-lg-2 col-6 col-md-3">
                <select class="form-select">
                  <option>Show 10</option>
                  <option>Show 30</option>
                  <option>Show 50</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table align-middle mb-0">
                <thead class="table-light">
                  <tr>
                    <th>Order ID</th>
                    <th>Dealer Name</th>
                    <th>Description</th>
                    <th>Units</th>
                    <th>File Name</th>

                    <th>Uploaded On </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.vouchers.map((voucher, index) => (
                    <tr key={index}>
                      <td>{voucher.id}</td>
                      <td>
                        <div class="d-flex align-items-center gap-3 cursor-pointer">
                          <div class="">
                            <p class="mb-0">{voucher.description}</p>
                          </div>
                        </div>
                      </td>
                      <td>{voucher.noOfUnit}</td>
                      <td>{voucher.fileName}</td>
                      <td>
                        {voucher.entryDate !== null
                          ? voucher.entryDate.split("T")[0]
                          : 0}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <nav class="float-end mt-3">
              <ul class="pagination">
                <li class="page-item disabled">
                  <a class="page-link" href="#">
                    Previous
                  </a>
                </li>
                <li class="page-item active">
                  <a class="page-link" href="#">
                    1
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    2
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    3
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    Next
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </main>
    );
  }
}

export default UploadHistory;
