import axios from "axios";
import React from "react";
import DatePicker from "react-datepicker";
import cookie from "react-cookies";
import "react-datepicker/dist/react-datepicker.css";
import { CLIQ_BASE_URL } from "../../webbase";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
class WalletHistory extends React.Component {
  constructor() {
    super();
    this.state = {
      someKey: "someValue",
      startDate: new Date(),
      endDate: new Date(),
      transactions: [],
      users:[],
      searchQuery: "",
      selectedDealer:'mobile@topupcliq.com',
      successfulWithdrawal:'0',
      successfulFunding:"0",
      failedFunding:"0",
      failedWithdrawal:'0',
      fundingHistory: [],
      token: cookie.load("token"),
      networks: [
        {
          id: 1,
          name: "MTN"
        },
        {
          id: 2,
          name: "9Mobile"
        },
        {
          id: 3,
          name: "Glo"
        },
        {
          id: 4,
          name: "Airtel"
        },
      ]
    };
    //binding this to event-handler functions
    this.setStartDate = this.setStartDate.bind(this);
    this.setEndDate = this.setEndDate.bind(this);
    this.getTransactions = this.getTransactions.bind(this);
    this.getDealers = this.getDealers.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }
  setStartDate(date) {
    this.setState({
      startDate: date,
    });
  }

  handleSearchChange(event) {
    this.setState({ searchQuery: event.target.value });
  }


  makeShortDate(datew) {
    datew = new Date(datew);
    let yy = datew.getFullYear();
    let mm = datew.getMonth();
    let dd = datew.getDate();
    let shortDate = `${yy}/${mm < 10 ? 0 : ""}${mm + 1}/${dd < 10 ? 0 : ""
      }${dd}`;
    return shortDate;
  }
  //write a funtion to get the transactions
  getTransactions() {
    let datee = this.makeShortDate(this.state.endDate);
    let yesterday = "";
    yesterday = this.makeShortDate(this.state.startDate)
    axios
      .get(CLIQ_BASE_URL + "/api/v3/w3/wallet-history?startDate="+ datee.split('/').join('-') +"&endDate=" +   yesterday.split('/').join('-')  +  "&emailAddress="+ this.state.selectedDealer, {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        //check if data isnt null
        if (res.data.data !== null) {
          this.setState({
            transactions: res.data.data,
            fundingHistory: res.data.data,
            successfulWithdrawal: res.data.summaryData.successfulWithdrawal,
            successfulFunding: res.data.summaryData.successfulFunding,
            failedFunding: res.data.summaryData.failedFunding,
            failedWithdrawal: res.data.summaryData.failedWithdrawal,
          });
        }
        else {

          this.setState({
            transactions: [],
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getDealers() {
    axios
        .get(CLIQ_BASE_URL + "/api/v3/w3/topupcliq-get-merchant",{
          
            headers: {
                authorization: this.state.token,
            },
        })
        .then((res) => {
            console.log(res.data);
            if (res.data.status === "2000") {
                this.setState({
                    users: res.data.data
                });

                // this.getTransactions();
            } else {
                toast.error(res.data.description);
            }
        })
        .catch((err) => {
            console.log(err);
        });
}

  setEndDate(date) {
    this.setState({
      endDate: date,
    });
  }

  componentDidMount() {
    this.getTransactions();
    this.getDealers();
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if merchantEmail has changed
    if (prevState.selectedDealer !== this.state.selectedDealer ||prevState.startDate !== this.state.startDate ||prevState.endDate !== this.state.endDate ) {
      this.getTransactions();
    }
  }

  render() {
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    });
    const filteredTransactions = this.state.transactions.filter((transaction) => {
      // Check messageFromParty, handle null, and filter by searchQuery
      const messageMatches = transaction.messageFromParty
        ? transaction.messageFromParty
            .toLowerCase()
            .includes(this.state.searchQuery.toLowerCase())
        : false;

      // Check if the status matches (if a filter is applied)
      const statusMatches = transaction.status
      ? transaction.status
          .toLowerCase()
          .includes(this.state.searchQuery.toLowerCase())
      : false;
      const typeMatches = transaction.type
      ? transaction.type
          .toLowerCase()
          .includes(this.state.searchQuery.toLowerCase())
      : false;

      // Return true if both conditions are met
      return messageMatches|| statusMatches || typeMatches;
    });
    return (
      <main className="page-content">
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="javascript:;">
                    <i className="bx bx-home-alt"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Wallet History
                </li>
              </ol>
            </nav>
          </div>
         
        </div>

        <div className="card">
          <div className="card-body">
            <form className="row">
              <div className="row">
                <div className="col-6">
                  <p className="form-label">Transaction Date:</p>
                  <DatePicker
                    selected={this.state.endDate}
                    onSelect={(date) => this.setEndDate(date)}
                    onChange={(date) => this.setEndDate(date)}
                  />
                </div>
               
              </div>
              <div className="row">
              <div className="col-lg-3 col-6 mt-4 col-md-3">
                                <select
                                    className="form-select"
                                    value={this.state.selectedDealer}
                                    onChange={(event) => {
                                        this.setState({
                                            selectedDealer: event.target.value,
                                        });
                                        
                                    }}
                                >
                                    {
                                        this.state.users.map((user, index) => (
                                            <option key={index} value={user?.emailAddress}>{user?.businessName}</option>
                                        ))
                                    }
                                </select>
                          
                </div>
                <div className="col-4 mt-4">
                
                <CSVLink
                    data={this.state.transactions}
                    filename={"transaction history Report.csv"}
                    target="_blank"
                  >
                    <button
                      className="btn btn-primary d-flex"
                      type="button"
                      style={{
                        width: "100%"
                      }}
                    >
                      <div>
                        <i class="bi bi-cloud-arrow-down-fill"></i>
                      </div>
                      <div className="mx-3">Download Report</div>
                    </button>
                  </CSVLink>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="card">
          <div className="card-header py-3">
            <div className="row g-3">
              <div className="col-lg-6 col-md-6 me-auto">
                <div className="ms-auto position-relative">
                  <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                    <i className="bi bi-search"></i>
                  </div>
                  <input
                    className="form-control ps-5"
                    type="text"
                    placeholder="Search by Message, status or type"
                    value={this.state.searchQuery}
                    onChange={this.handleSearchChange}
                  />
                </div>
              </div>
              <div className="row row-cols-1 my-3 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-4">
          <div className="col">
            <div className="card overflow-hidden radius-10">
              <div className="card-body">
                <div className="d-flex align-items-stretch justify-content-between overflow-hidden">
                  <div className="w-50">
                    <p>Failed Withdrawal</p>
                    <h4 className="">{formatter.format(this.state.failedWithdrawal) }</h4>
                  </div>
                  <div className="w-50">
                    <p className="mb-3 float-end text-success"></p>
                    <div id="chart4"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card overflow-hidden radius-10">
              <div className="card-body">
                <div className="d-flex align-items-stretch justify-content-between overflow-hidden">
                  <div className="w-50">
                    <p>Successful Withdrawal</p>
                    <h4 className="">{formatter.format(this.state.successfulWithdrawal)}</h4>
                  </div>
                  <div className="w-50">
                    <p className="mb-3 float-end text-success"></p>
                    <div id="chart1"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card overflow-hidden radius-10">
              <div className="card-body">
                <div className="d-flex align-items-stretch justify-content-between overflow-hidden">
                  <div className="w-50">
                    <p>Successful Funding</p>
                    <h4 className="">{formatter.format(this.state.successfulFunding)}</h4>
                  </div>
                  <div className="w-50">
                    <p className="mb-3 float-end text-success"></p>
                    <div id="chart2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card overflow-hidden radius-10">
              <div className="card-body">
                <div className="d-flex align-items-stretch justify-content-between overflow-hidden">
                  <div className="w-50">
                    <p>Failed Funding</p>
                    <h4 className="">{formatter.format(this.state.failedFunding)}</h4>
                  </div>
                  <div className="w-50">
                    <p className="mb-3 float-end text-success"></p>
                    <div id="chart3"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
              {/* <div className="col-lg-2 col-6 col-md-3">
                <select className="form-select">
                  <option>Show 10</option>
                  <option>Show 30</option>
                  <option>Show 50</option>
                </select>
              </div> */}
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table
                id="example"
                class="table table-striped table-bordered"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Fee</th>
                    <th>Business name</th>
                    <th>Message</th>
                    <th>Debit amount</th>
                    <th>Credit Amount</th>
                    <th>Type</th>
                    <th>Transaction date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    filteredTransactions.map((transaction, index) =>{ 
                      const transactionDate = new Date(transaction?.transactionDate);
                      const formattedDate = transactionDate.toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      });

                      const formattedTime = transactionDate.toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      });
                      return(
                      
                      <tr key={index}>
                        <td>{transaction?.emailAddress}</td>
                        <td>{formatter.format(transaction?.fees) }</td>
                        <td>{transaction?.businessName}</td>
                        <td>{transaction?.messageFromParty}</td>
                        <td> {  formatter.format(transaction?.debitAmount)}</td>
                        <td>{formatter.format(transaction?.creditAmount)} </td>
                        <td>{transaction?.type} </td>
                        <td>{formattedDate} {formattedTime}</td>
                        <td>{transaction?.status}</td>
                      </tr>
                    )})
                  }
                  <tr></tr>
                </tbody>
              </table>
            </div>
            {/* <nav class="float-end mt-3">
              <ul class="pagination">
                <li class="page-item disabled">
                  <a class="page-link" href="#">
                    Previous
                  </a>
                </li>
                <li class="page-item active">
                  <a class="page-link" href="#">
                    1
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    2
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    3
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    Next
                  </a>
                </li>
              </ul>
            </nav> */}
          </div>
        </div>
      </main>
    );
  }

}

export default WalletHistory;
