import React from "react";

class Main extends React.Component {
  constructor() {
    super();
    this.state = {
      someKey: "someValue",
    };
  }

  render() {
    return (
      <div className="page-content" style={{ marginLeft: 0 }}>
        <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-4">
          <div className="col">
            <div className="card overflow-hidden radius-10">
              <div className="card-body">
                <div className="d-flex align-items-stretch justify-content-between overflow-hidden pb-2">
                  <div className="w-50">
                    <p>No of Dealers</p>
                    <h5 className=""> 10</h5>
                  </div>
                  <div className="w-50">
                    <p className="mb-3 float-end text-success">your funds</p>
                    <div id="chart1"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-12 col-xl-12 d-flex">
            <div className="card radius-10 w-100">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <h6 className="mb-0">List of Dealers</h6>
                  <div className="fs-5 ms-auto dropdown">
                    <div
                      className="dropdown-toggle dropdown-toggle-nocaret cursor-pointer"
                      data-bs-toggle="dropdown"
                    >
                      <i className="bi bi-three-dots"></i>
                    </div>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Another action
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="table-responsive mt-2">
                  <table className="table align-middle mb-0">
                    <thead className="table-light">
                      <tr>
                        <th>Batch ID</th>
                        <th>Description</th>
                        <th>Unit Quantity</th>

                        <th>Network</th>
                        <th>Price</th>
                        <th>Total Amount</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <div className="product-info">
                              <h6 className="product-name mb-1"></h6>
                            </div>
                          </div>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.setState({
      someKey: "otherValue",
    });
  }
}

export default Main;
