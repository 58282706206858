import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import cookie from "react-cookies";
import { WEB_BASE_URL } from "../../webbase";

class TransferModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      someKey: "someValue",
      isOTPSent: false,
      token: cookie.load("token"),
      otp: "",
    };

    this.onFundUser = this.onFundUser.bind(this);
  }

  onFundUser() {
    //event.preventDefault();
    const id = toast.loading("Please wait...");

    fetch(WEB_BASE_URL + "/admin/initiate-transfers?requestId=" + this.props?.requestID, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: this.state.token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if (res.status === "2000") {
          toast.update(id, {
            render: "OTP successfully sent for confirmation",
            type: "success",
            isLoading: false,
          });
          this.setState({
            isOTPSent: true,
          })
        } else {
          toast.update(id, {
            render: res.description,
            type: "error",
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }


  render() {
    return (
      <div
        className="modal fade"
        id={this.props.id}
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {this.props.title}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form className="row g-3">
                <div className="col-12">
                  <label className="form-label">User Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={this.props.formEmail}
                    disabled={true}
                  />
                </div>
                <div className="col-12">
                  <label className="form-label">Amount (in Naira)</label>
                  <input
                    type="number"
                    className="form-control"
                    value={this.props.amount}
                  />
                </div>
                <div className="col-12">
                  <label className="form-label">OTP</label>
                  <input
                    type="number"
                    className="form-control"
                    onChange={e => {
                      this.setState({
                        otp: e.target.value,
                      })
                    }}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                onClick={(e) => {
                  if (this.state.isOTPSent) {
                    this.props.onSubmit(e);
                  }
                  else {
                    this.onFundUser();
                  }
                }}
                type="button"
                className="btn btn-primary"
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.setState({
      someKey: "otherValue",
    });
  }
}

export default TransferModal;
